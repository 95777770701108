<template>
  <base-missing-data-page :url-img="$options.imageDataMissing" class="announcement-missing-data">
    <template #message>
      {{ $t('announcement.no_announcements', { status: currentStatusText }) }}
    </template>

    <template v-if="!isSent" #buttons>
      <v-btn large color="primary" :to="{ name: $options.ANNOUNCEMENTS_CREATE }" exact>
        {{ $t('announcements.new_announcement') }}
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </template>
  </base-missing-data-page>
</template>

<script>
// Components
import BaseMissingDataPage from '@/components/BaseMissingDataPage.vue';

import { SENT, SCHEDULED, DRAFTS } from '@/constants/entityStatuses';
import { ANNOUNCEMENTS_CREATE } from '@/constants/routes';

import imageDataMissing from '@/assets/imageMissingData/missing-data-announcements.svg';

export default {
  name: 'AnnouncementMissingData',

  components: { BaseMissingDataPage },

  props: {
    status: { type: String, required: true },
  },

  computed: {
    isSent() {
      return this.status === SENT;
    },

    statusTranslations() {
      return {
        [SENT]: this.$t('announcements.sent'),
        [SCHEDULED]: this.$t('announcements.scheduled'),
        [DRAFTS]: this.$t('announcements.drafts'),
      };
    },

    currentStatusText() {
      return this.statusTranslations[this.status].toLowerCase();
    },
  },

  imageDataMissing,

  ANNOUNCEMENTS_CREATE,
};
</script>
